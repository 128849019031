window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

require('./php');

window.bootstrap = require('bootstrap') 

window.addEventListener('load', function()
{
    const onclick = function()
    {
        const $form = this.closest('form');
        let name = this.dataset.name;
        let value = this.dataset.value;
        let action = this.dataset.action;

        if(!name)
        {
            if(this.classList.contains('btnBack'))
            {
                name = '_back';
            }
            else if(this.classList.contains('btnNext'))
            {
                name = '_next';
            }
        }

        if(!value)
        {
            value = '1';
        }

        $form.insertAdjacentHTML('beforeend', '<input type="hidden" name="' + name + '" value="' + value + '" />')

        if (action)
        {
            $form.action = window.php.rtrim(action, '/');
        }

        $form.submit();

        return false;
    };
    
    document.querySelectorAll(".btnSubmit, .btnBack, .btnNext").forEach(function(button)
    {
        button.addEventListener('click', onclick);
    });

    let toasts = [].slice.call(document.querySelectorAll('.toast'))
    toasts = toasts.map(function (toast) {
        return new window.bootstrap.Toast(toast)
    })
    toasts.map(function(toast)
    {
        toast.show()
    })
});